import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
var Layout = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return props.layout;
});
var withLayoutProps = function withLayoutProps(Component) {
  var WrapComponent = function WrapComponent(props) {
    var _Component$name;
    var layout = props.layout;
    var testid = "mod-".concat((_Component$name = Component.name) == null ? void 0 : _Component$name.toLowerCase());
    return Object.keys(layout).length ? React.createElement(Layout, {
      layout: layout,
      "data-testid": testid
    }, React.createElement(Component, props)) : React.createElement(Component, props);
  };
  WrapComponent.defaultProps = {
    layout: {}
  };
  process.env.NODE_ENV !== "production" ? WrapComponent.propTypes = {
    layout: shape({})
  } : void 0;
  WrapComponent.dataSource = Component.dataSource;
  WrapComponent.ssr = Component.ssr;
  WrapComponent.displayName = Component.displayName || Component.name;
  return WrapComponent;
};
export default withLayoutProps;