import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["id"],
  _excluded2 = ["product"];
import { parsePlaceholders } from '@magalu/mixer-structure';
import { useStorage } from '@magalu/mixer-utils';
export var BANNERS_COMPONENT_NAME = 'AdsBanner';
export var CAROUSEL_COMPONENT_NAME = 'AdsCarousel';
export var HEADER_COMPONENT_NAME = 'HeaderDesktop';
export var PRODUCTS_COMPONENT_NAME = 'AdsShowcase';
var ADS_COMPONENTS = [PRODUCTS_COMPONENT_NAME, BANNERS_COMPONENT_NAME, CAROUSEL_COMPONENT_NAME];
var getCategories = function getCategories(category, viewedProducts, categoryPageId) {
  if (category == null ? void 0 : category.id) {
    return [category.id];
  }
  if (categoryPageId) {
    return [categoryPageId];
  }
  if (viewedProducts == null ? void 0 : viewedProducts.length) {
    return _toConsumableArray(new Set(viewedProducts)).slice(-5);
  }
  return ['MO', 'EP', 'CM', 'UD', 'ME'];
};
var buildQuery = function buildQuery(_ref) {
  var _data$category, _data$category$, _data$product, _get, _get$items;
  var data = _ref.data,
    placements = _ref.placements,
    structure = _ref.structure;
  var _useStorage = useStorage('viewing-history'),
    get = _useStorage.get;
  var cookies = structure.cookies;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    _ref2$category = _ref2.category,
    category = _ref2$category === void 0 ? {} : _ref2$category;
  var categoryPageId = (data == null ? void 0 : (_data$category = data.category) == null ? void 0 : (_data$category$ = _data$category[0]) == null ? void 0 : _data$category$.id) || null;
  var omittedProductIds = [data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id].filter(Boolean);
  var viewedProducts = ((_get = get()) == null ? void 0 : (_get$items = _get.items) == null ? void 0 : _get$items.map(function (item) {
    return item.category.id;
  })) || [];
  var banners = [];
  var products = [];
  var channelCode;
  var _ref3 = cookies || {},
    partnerId = _ref3.partnerId,
    location = _ref3.location,
    enableFallbackBanner = _ref3.enableFallbackBanner;
  placements.forEach(function (_ref4) {
    var name = _ref4.name,
      _ref4$categories = _ref4.categories,
      categories = _ref4$categories === void 0 ? [] : _ref4$categories,
      limit = _ref4.limit,
      slot = _ref4.slot,
      consumer = _ref4.consumer,
      channel = _ref4.channel,
      searchTerm = _ref4.searchTerm;
    if (name === PRODUCTS_COMPONENT_NAME) {
      products.push({
        categories: getCategories(category, viewedProducts, categoryPageId),
        consumer: consumer,
        limit: limit,
        slot: slot
      });
    } else if ([BANNERS_COMPONENT_NAME, CAROUSEL_COMPONENT_NAME].includes(name) && !enableFallbackBanner) {
      var parsed = parsePlaceholders({
        categories: categories,
        searchTerm: searchTerm
      }, structure);
      banners.push(_extends({
        categories: categories,
        consumer: consumer,
        limit: limit,
        slot: slot
      }, parsed));
    }
    if (channel) {
      channelCode = channel;
    }
  });
  return _extends({}, banners.length && {
    banners: banners
  }, {
    metadata: {
      channelCode: channelCode,
      isLoyalty: false,
      partnerId: partnerId
    },
    omitProductIds: omittedProductIds,
    products: products,
    zipcode: (location == null ? void 0 : location.zipCode) || ''
  });
};
var processComponents = function processComponents(structure) {
  var placementMap = new Map();
  var addAdComponent = function addAdComponent(_ref5) {
    var staticProps = _ref5["static"],
      name = _ref5.name;
    if (!ADS_COMPONENTS.includes(name)) return;
    var channel = staticProps.channel,
      categories = staticProps.categories,
      consumer = staticProps.consumer,
      _staticProps$limit = staticProps.limit,
      limit = _staticProps$limit === void 0 ? 1 : _staticProps$limit,
      slot = staticProps.slot,
      slots = staticProps.slots,
      searchTerm = staticProps.searchTerm;
    var slotArr = (slots !== null && slots !== void 0 ? slots : []).concat(slot ? {
      slot: slot
    } : []);
    var placementData = _extends({
      categories: categories,
      channel: channel,
      consumer: consumer,
      limit: limit,
      name: name
    }, searchTerm && {
      searchTerm: searchTerm
    });
    var uniqueSlots = slotArr.filter(function (_ref6) {
      var slotData = _ref6.slot;
      return slotData && !placementMap.has(slotData);
    });
    uniqueSlots.forEach(function (slotData) {
      return placementMap.set(slotData, _extends({}, placementData, slotData));
    });
  };
  var processComponent = function processComponent(component) {
    addAdComponent(component);
    if (component == null ? void 0 : component.children) {
      component.children.forEach(processComponent);
    }
    if (component.type === 'group') {
      component.components.forEach(processComponent);
    }
    if (component.name === HEADER_COMPONENT_NAME) {
      var _component$static$men = component["static"].menuData.data,
        categoryList = _component$static$men.categoryList,
        allCategories = _component$static$men.allCategories;
      var menuBanners = categoryList.list.concat(allCategories).map(function (_ref7) {
        var adUnit = _ref7.adUnit;
        return {
          name: BANNERS_COMPONENT_NAME,
          "static": adUnit["static"]
        };
      });
      menuBanners.forEach(processComponent);
    }
  };
  structure.components.forEach(processComponent);
  return placementMap;
};
var buildQueryVariablesFromComponents = function buildQueryVariablesFromComponents(_ref8) {
  var data = _ref8.data,
    structure = _ref8.structure;
  if (Array.isArray(structure.components)) {
    var placementMap = processComponents(structure);
    if (placementMap.size) {
      return buildQuery({
        data: data,
        placements: Array.from(placementMap.values()),
        structure: structure
      });
    }
  }
  return null;
};
var parseAdsBanners = function parseAdsBanners(banners) {
  return banners.reduce(function (acc, banner) {
    (acc[banner.slot] = acc[banner.slot] || []).push(banner);
    return acc;
  }, {});
};
var parseProducts = function parseProducts(products) {
  return products.reduce(function (acc, product) {
    var _product$product = product.product,
      id = _product$product.id,
      restProduct = _objectWithoutProperties(_product$product, _excluded),
      rest = _objectWithoutProperties(product, _excluded2);
    var flattenedProduct = _extends({
      id: id
    }, restProduct, rest);
    (acc[product.slot] = acc[product.slot] || []).push(flattenedProduct);
    return acc;
  }, {});
};
export { buildQuery, buildQueryVariablesFromComponents, getCategories, parseAdsBanners, parseProducts };